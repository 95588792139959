/*****HOME*****/
.home {
  align-items: center;
  background-color: var(--concrete);
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  padding: 0px 0;
  width: auto;
}
.overlap-groupHome {
  height: 252px;
  position: relative;
  width: auto;
}
.bannerHome {
  align-items: flex-start;
  background-position: 50% 60%;
  background-size: cover;
  display: flex;
  height: 28rem;
  min-width: 100vmax;
  background-image: url("./../assets/banner.jpg");
}

/*selector idioma*/
/* .selector-idiomaHome {
    height: 30px;
    margin-top: 10px;
  }
  .banderaIdiomaHome {
    height: 28px;
    object-fit: cover;
    width: 28px;
    margin-left: 0px;
  }
  .castellanoHome {
    align-self: flex-start;
    letter-spacing: 0.00px;
    line-height: 60px;
    min-height: 32px;
    min-width: 132px;
    margin-top: -45px;
    text-align: center;
    white-space: nowrap;
  }
  .icon-ionic-md-arrow-dropdown-2Home {
    height: 9px;
    margin-bottom: 1.0px;
    margin-left: 170px;
    object-fit: cover;
    width: 18px;
    margin-top: 0px;
  }

  
  /*selector*/
.selectorHome {
  background-color: var(--concrete);
  border-radius: 10px;
  box-shadow: 6px 6px 18px #00000029 ;
  margin-top: -50px;
}
.fila-Carta{
  padding:0;
  box-shadow: 6px 6px 18px #00000029 ;
  background-color: white;
  width: 58%;
}
.flex-row-Home {
 
}
.text-precioEve{
  letter-spacing: 0px;
  line-height: 40px;
  min-height: 29px;
  margin-left: 20px;
}
.flex-colHome {
  display: flex;
  min-height: 100px;
  justify-content: center;
}
.selecciona-el-cicloHome {
  color: var(--thunder);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl2);
  padding: 40px;
  text-align: center;
}

/*calendario*/

/*****CALENDARIO*****/

.calendario-eventos {
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 1.4rem;
}
.parrafoIndicacion{
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
  text-align: center;
}
.parrafoIndicacion2{
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 10px
}
/*****CARTA-EVENTO*****/
.carta-evento {
  width: 400px;
  margin-top: 50px;
  height: 450px;
  margin-bottom: 250px;
  margin-right: auto;
  margin-left: auto;
}
.overlap-groupCart {
  position: relative;
}
.cartaEve {
  border-radius: 10px;
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 450px;
  left: 45px;
  box-shadow: 6px 6px 18px #00000029;
  width: 310px;
  position: absolute;
  top: 54px;
}
.cartaEve2 {
  border-radius: 10px;
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  left: 45px;
  box-shadow: 6px 6px 18px #00000029;
  width: 310px;
  position: absolute;
  top: 400px;
}
.infoEve {
  box-shadow: -5px -5px 5px #ffffff29;
  background-color: var(--concrete);
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 510px;
  height: fit-content
}
.nombreEvento {
  line-height: 40px;
  margin-left: 15px;
  margin-top: 20px;
  font-size: 20px;
}

.hora-1830Eve {
  letter-spacing: 0px;
  min-height: 29px;
  min-width: 119px;
  white-space: nowrap;
  margin-left: 20px;
  margin-top: 5px;
  font-size: 18px;
}
.text-2Eve {
  letter-spacing: 0px;
  line-height: 35px;
  min-height: 29px;
  margin-left: 20px;
  font-size: 18px;
}
.fechaEve {
  align-items: center;
  background-color: var(--concrete);
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  display: flex;
  height: 65px;
  justify-content: center;
  left: 40px;
  min-width: 40px;
  padding: 0 6px;
  position: absolute;
  top: 30px;
}
.numberEve {
  font-size: 50px;
  color: black;
}
/*control de acceso*/
.containerPrincipal {
  position: absolute;
    left: 50%;
    margin-top: 150px;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: white;
}
.containerSecundario{
  text-align: center;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,300,500,600");
