.selector-idioma {
    display: flex;
    align-items: center;
  }
  .botonIdioma{
      margin-left: 20px;
      background-color: transparent;
      align-items: center;
  }
  .mask-group-1 {
    height: 28px;
    object-fit: cover;
    width: 28px;
    margin-left: -60px;
    
  }
  .banderaCastellano{
    height: 28px;
    object-fit: cover;
    width: 28px;
    margin-left: -180px;
  }
  .castellanoBoton{
    align-self: flex-start;
    letter-spacing: 0.00px;
    line-height: 60px;
    margin-left: -10px;
    margin-top: -45px;
    min-height: 32px;
    min-width: 100px;
    text-align: justify;
    white-space: nowrap;
  }
  .castellano {
    align-self: flex-start;
    letter-spacing: 0.00px;
    line-height: 60px;
    margin-left: -20px;
    margin-top: -45px;
    min-height: 32px;
    min-width: 100px;
    text-align: justify;
    white-space: nowrap;
    
  }
